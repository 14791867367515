import React from 'react';

import Btn from 'components/Btn';

import Events from 'utils/Events';
import Settings from 'utils/Settings';
import { get, getImageUrl } from 'utils/magnolia';
import { GetProductById } from 'utils/commerce';
import { ReactComponent as ArrowDark } from 'img/arrow-dark.svg';

function renderPrice(price, discountedPrice) {
  if (discountedPrice) {
    return (
      <>
        <span className='ProductPage__discountedPrice'>{price}</span> {discountedPrice}
      </>
    );
  }

  return <>{price}</>;
}
class Product extends React.Component {
  state = {};

  fetchProduct = async () => {
    const pathname = window.location.pathname;

    if (this.state.pathname === pathname) return;

    const productId = window.location.pathname.replace(new RegExp('.*/products/', 'g'), '');
    const product = await GetProductById(productId, true);

    const productDecorationResponse = await get(
      '/.rest/delivery/product-decoration?picker%5Blike%5D=%25' + productId + '%25'
    );

    let productDecorations;

    if (productDecorationResponse.results.length > 0) {
      productDecorations = productDecorationResponse.results[0];

      productDecorations.additionalImages.forEach((imageId, index) => {
        product.images.push(getImageUrl(imageId));
      });
    }

    Events.emit('updateTitle', product.name);
    Events.emit('xo-view', product);

    // send 'Product Viewed' Event to Segment
    if (window.analytics) {
      window.analytics.track('Product Viewed', {
        product_id: product.id,
        product_name: product.name,
      });
    }

    this.setState({ product, activeImage: 0, productDecorations, pathname });
  };

  componentDidMount() {
    this.fetchProduct();
  }

  componentDidUpdate() {
    this.fetchProduct();
  }

  renderModelViewer = (productDecorations) => {
    const props = {};

    if (productDecorations['gltf-glb']) props.src = getImageUrl(productDecorations['gltf-glb']);
    if (productDecorations['usdz']) props['ios-src'] = getImageUrl(productDecorations['usdz']);

    return <model-viewer camera-controls auto-rotate ar {...props}></model-viewer>;
  };

  renderExtraDescription = (productDecorations) => {
    if(!productDecorations) return null;

    return (<div>
      <div className='title title--2'>&nbsp;</div>
        <div className="ProductPage__description"
          dangerouslySetInnerHTML={{__html: productDecorations['additionalDescription']}}/>
      </div>);
  };

  renderProduct = (product) => {
    const { activeImage, productDecorations } = this.state;
    const { name, description, price, discountedPrice, images } = product;
    const translations = Settings.getItem('translations');

    return (
      <div className='ProductPage'>
        <div className='ProductPage__images-wrapper'>
          <div className='ProductPage__images text-center'>
            <div className='ProductPage__image'>
              {activeImage === 'ar' ? this.renderModelViewer(productDecorations) : <img src={images[activeImage]} alt='' />}
            </div>
            {images && (
              <div className='ProductPage__imageSwitch'>
                {images.map((image, i) => {
                  const isActive = activeImage === i;

                  return (
                    <React.Fragment key={'ProductPage__imageSwitch-' + i}>
                      <div className={isActive ? 'active' : ''} onClick={() => this.setState({ activeImage: i })}>
                        0{i + 1}
                      </div>
                      {isActive && <ArrowDark />}
                    </React.Fragment>
                  );
                })}
                {(productDecorations && (productDecorations['gltf-glb'] || productDecorations['usdz']))
                    && <div onClick={() => this.setState({ activeImage: 'ar' })}>AR</div>}
              </div>
            )}
          </div>
        </div>
        <div className='ProductPage__info-wrapper'>
          <div className='ProductPage__info text-center'>
            {discountedPrice && <div className='ProductPage__sale'>SALE</div>}
            <div className='ProductPage__name'>{name}</div>
            <div className='ProductPage__price'>{renderPrice(price, discountedPrice)}</div>
            <div className='title title--2'>{translations.description}</div>
            <p className='ProductPage__description '>{description}</p>
            <div
              onClick={() => {
                Events.emit('xo-add-to-cart', product);
              }}
            >
              <Btn text={translations.addToCart} isSecondaryStyle={true} />
            </div>

            {this.renderExtraDescription(productDecorations)}

          </div>
        </div>
      </div>
    );
  };

  render() {
    const { product } = this.state;

    return product ? this.renderProduct(product) : null;
  }
}

export default Product;
